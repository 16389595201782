import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { informationCategoriesCleanUp } from 'state/actions/informationCategories';
import { regexDescription, regexInput } from 'utils/regex';
import './ModalInformationCategoryForm.scss';
import { log } from 'utils';

const ModalInformationCategoryForm = ({
  category,
  setCategory,
  visible,
  isEditing,
  onCancel,
  onCreate,
}) => {
  const [form] = Form.useForm();

  const { loading } = useSelector(
    (state) => ({
      loading: state.informationCategories.loading,
    }),
    shallowEqual
  );

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(informationCategoriesCleanUp());
  }, [dispatch]);

  // const onChangeHandler = useChangeHandler(setCategory);

  const onSave = (values) => {
    if (
      (!regexInput(values.name) && values.name) ||
      (!regexDescription(values.description) && values.description)
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(values.name) &&
        values.name && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(values.description) &&
        values.description && [
          {
            msg: 'This field cannot contain a special characters',
          },
        ];
      setErrors(newErrors);
    } else {
      setErrors({
        name: '',
        description: '',
      });
      onCreate({
        ...category,
        id: category._id,
        name: values.name,
        description: values.description,
      });
      form.resetFields();
    }
  };

  const handleChange = (name, value) => {
    setCategory({
      ...category,
      [name]: value,
    });
  };

  return (
    <Modal
      visible={visible}
      title={
        isEditing
          ? 'Edit Information Category'
          : 'Create New Information Category'
      }
      className="adsign-modal"
      closeIcon={<i className="feather-x" />}
      okButtonProps={{
        className: 'button-submit',
      }}
      cancelButtonProps={{
        className: 'button-cancel',
      }}
      okText="Submit"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onSave(values);
          })
          .catch((info) => {
            log('Validate Failed:', info);
          });
      }}
    >
      <Form
        layout="vertical"
        form={form}
        // onChange={onChangeHandler}
        initialValues={{
          name: category.name,
          description: category.description,
        }}
      >
        {log(loading, 'loading')}
        <Form.Item label="Name" required>
          <Input
            placeholder="Enter Category's name"
            onChange={(e) => handleChange('name', e.target.value)}
            value={category.name}
          />
        </Form.Item>
        {errors &&
          errors.errors &&
          errors.errors.name &&
          errors.errors.name.map((name, idx) => (
            <div className="error-message" key={idx}>
              {name.msg}
            </div>
          ))}

        <Form.Item label="Description">
          <Input.TextArea
            rows={4}
            placeholder="Description"
            onChange={(e) => handleChange('description', e.target.value)}
            value={category.description}
          />
        </Form.Item>
        {errors &&
          errors.errors &&
          errors.errors.description &&
          errors.errors.description.map((name, idx) => (
            <div className="error-message" key={idx}>
              {name.msg}
            </div>
          ))}
      </Form>
    </Modal>
  );
};

ModalInformationCategoryForm.propTypes = {
  informationCategoryData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    siteId: PropTypes.string,
    modifiedAt: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
};

export default ModalInformationCategoryForm;
